
import { types } from 'mobx-state-tree';

import moment from 'moment-timezone';

import DateTime from '@utils/mst/types/DateTime';

import Coach from './Coach';

export enum OrganizationStatusLabel {
  Active = 'Active',
  PendingDeactivation = 'Pending Deactivation',
  Inactive = 'Inactive',
}

export const SELF_PAID_ORG_NAME = 'Self-Paid';

const Organization = types
  .model('Organization', {
    id: types.identifierNumber,
    createdAt: DateTime,
    createdBy: types.reference(Coach),
    industry: types.maybeNull(types.string),
    name: types.string,
    hasResources: types.snapshotProcessor(types.boolean, {
      preProcessor(snapshot) {
        return !!snapshot;
      },
    }),
    isActive: types.boolean,
    pendingDeactivation: types.boolean,
    shareAccessCodeEnabled: types.boolean,
    ringEnabled: types.boolean,
    coachingEnabled: types.boolean,
    deactivatedBy: types.maybeNull(types.reference(Coach)),
    deactivatedAt: types.maybeNull(DateTime),
  })
  .views((self) => ({
    get createdAtMoment(): string {
      return moment(self.createdAt).format('DD MMMM YYYY hh:mm A');
    },
    get deactivatedAtMoment(): string | null {
      return self.deactivatedAt ? moment(self.deactivatedAt).format('DD MMMM YYYY hh:mm A') : null;
    },
    get organizationStatusLabel(): OrganizationStatusLabel {
      if (self.pendingDeactivation) {
        return OrganizationStatusLabel.PendingDeactivation;
      }
      if (self.isActive) {
        return OrganizationStatusLabel.Active;
      }
      return OrganizationStatusLabel.Inactive;
    },
  }));

export default Organization;
